<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">

            <v-row class="auth-row ma-0">
                <v-col lg="7"
                       class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper login-bg">
                        <div class="logo-size">
                            <img class="logo"
                                 :src="require(`@/assets/images/logo-designs/Reverse/Digital/myboat-logo-reverse-rgb.svg`)">
                        </div>

                    </div>
                </v-col>

                <v-col lg="5"
                       class="auth-bg pa-9 pb-0">
                    <v-row style="justify-content: end;">

                        <app-bar-i18n></app-bar-i18n>

                    </v-row>
                    <v-row class="pt-16">
                        <v-col cols="12"
                               sm="8"
                               md="6"
                               lg="12"
                               class="mx-auto">
                            <v-form ref="Form"
                                    class="multi-col-validation"
                                    lazy-validation
                                    @submit.prevent="submit"
                                    v-model="valid">
                                <v-card flat>
                                    <v-card-text class="d-flex align-start">

                                        <v-icon class="arabic-arrow"
                                                color="green-darken-2"
                                                style="font-size: 31px;cursor: pointer;"
                                                @click="back">
                                            mdi-arrow-left
                                        </v-icon>

                                        <div>
                                            <p class="text-2xl text-center text--primary mb-2">

                                                <span class="font-weight-light"> {{ $t('Enter') }}</span> <span
                                                      class="font-weight-bold">{{ $t('One Time Passcode') }}</span>
                                                <!-- 👋🏻 -->
                                            </p>
                                            <p class="mb-2 mt-6 text-center"
                                               style="font-size:12px; font-weight:500">
                                                {{ $t('Please enter your 4 digit One Time') }}
                                                {{ $t('passcode sent to you on email address') }}
                                                <span class="font-weight-bold">{{ sendEmailUser ? sendEmailUser : ''
                                                }}</span>
                                            </p>
                                        </div>
                                    </v-card-text>

                                    <!-- login form -->
                                    <v-card-text>
                                        <p class="font-weight-semibold text-black mb-2">
                                            {{ $t('Enter 4-Digit Code') }}
                                        </p>
                                        <div dir="ltr"
                                             class="otp-input">
                                            <v-otp-input v-model="otp"
                                                         :rules="[genericRules.required, genericRules.otp]"
                                                         @input="onOtpInput"
                                                         @blur="onInputBlur"
                                                         ref="otpInput"
                                                         :hide-details="true"
                                                         type="number"
                                                         @keydown.enter="submit"
                                                         onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                         length="4">
                                            </v-otp-input>
                                            <div v-if="otpError"
                                                 style="font-size: 12px;"
                                                 class="ar-otp error-message mb-3">
                                                {{ $t(otpErrorMessage) }}
                                            </div>
                                        </div>

                                        <v-btn block
                                               color="primary"
                                               :disabled="!valid"
                                               type="button"
                                               @click="submit"
                                               class="text-capitalize mt-3">
                                            {{ $t('Submit') }}
                                        </v-btn>
                                    </v-card-text>

                                    <timer :showTimer="showTimer"
                                           :formattedTime="formattedTime"
                                           @retry="resendCode"></timer>

                                    <!-- <v-card-actions class="d-flex justify-center align-center">
                                        <router-link @click.native="resendCode"
                                                     to=""
                                                     :disabled="disableResend"
                                                     style="color: black;"
                                                     class="d-flex align-center text-sm">
                                            {{ $t('Resend Code') }}
                                        </router-link>
                                    </v-card-actions> -->
                                </v-card>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import userServices from '@/services/user-services';
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue';

import timer from './timer.vue';

import store from '@/store';

import { getCurrentInstance, ref } from '@vue/composition-api'

export default {
    components: {
        AppBarI18n,
        timer
    },
    data: () => ({
        // vm: getCurrentInstance().proxy,
        time: 60,
        timerId: null,
        showTimer: true,
        disableResend: true,
        valid: false,
        Form: null,
        otp: '',
        sendEmailUser: '',
        sendPassword: '',
        type: null,
        genericRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[+0-9,.]*$/.test(v) || 'Only numbers are allowed',
            characters: v => /^[a-z A-Z]*$/.test(v) || 'Only characters are allowed',
            email: v => /.+@.+/.test(v) || 'E-mail must be valid',
            otp: (v) => (v && v.length == 4) || "Enter valid OTP",
        },
        otpError: false,
        otpErrorMessage: '',
    }),

    mounted()
    {

        this.sendEmailUser = localStorage.getItem('email');

        this.type = router.currentRoute.params.type;

        if (this.type == 'login')
        {
            this.sendPassword = localStorage.getItem('password');
        }

        this.showTimer = true
        this.timerId = setInterval(() =>
        {
            this.time -= 1
            if (this.time <= 0)
            {
                clearInterval(this.timerId)
                this.showTimer = false
                this.time = 60
                this.timerId = null
            }
        }, 1000)
    },
    computed: {
        formattedTime:
        {
            get()
            {
                const seconds = this.time % 60
                return `${seconds.toString().padStart(2, '0')}`
            },
            set(newName)
            {
                return newName
            }
        }
    },
    methods: {
        back()
        {
            window.history.back();
        },
        onOtpInput()
        {
            if (this.otp.length !== 4)
            {
                this.otpError = true;
                this.otpErrorMessage = 'OTP is required.';
                // const inputElement = this.$refs.otpInput.$el.querySelector('input');
                // if (inputElement)
                // {
                //     inputElement.focus(); // Move focus back into the input
                // }
            } else
            {
                this.otpError = false;
            }
        },
        onInputBlur()
        {
            if (!this.otp.length)
            {
                this.otpError = true;
                this.otpErrorMessage = 'OTP is required.';
            }
        },
        resendCode()
        {
            this.time = 59;

            const seconds = this.time % 60
            this.formattedTime = `${seconds.toString().padStart(2, '0')}`

            this.showTimer = true;
            this.timerId = setInterval(() =>
            {
                this.time -= 1
                if (this.time <= 0)
                {
                    clearInterval(this.timerId)
                    this.showTimer = false
                    this.time = 60
                    this.timerId = null
                }
            }, 1000)

            let object = {};
            let url = '';

            if (this.type == 'login')
            {
                object = {
                    username: this.sendEmailUser,
                    password: this.sendPassword
                };
                url = 'v2/authenticate';
            }
            else
            {
                object = {
                    username: this.sendEmailUser
                };
                url = 'generate-otp';
            }

            userServices.resetPassword(object, url).then(res =>
            {


            })
        },
        submit()
        {
            this.$refs.Form.validate();
            if (this.$refs.Form.validate())
            {
                let email = localStorage.getItem('email');
                let url = '';

                let obj = {
                    "otp": this.otp,
                    "username": email
                };
                localStorage.setItem('otp', this.otp);

                if (this.type == 'login')
                {
                    // object = {
                    //     username: this.sendEmailUser,
                    //     password: this.sendPassword
                    // };
                    url = 'v2/validate';
                }
                else
                {
                    // object = {
                    //     username: this.sendEmailUser
                    // };
                    url = 'validate-otp';
                }

                userServices.validateOtp(obj, url).then(res =>
                {
                    if (res?.status == 200)
                    {
                        if (this.type == 'login')
                        {
                            localStorage.setItem('authorization', res.data.data.access_authorization)

                            store.set('accounts/userData', res.data.data.user)
                            localStorage.setItem('role_name', res.data.data.user.role)
                            localStorage.setItem('userData', JSON.stringify(res.data.data.user))

                            const userAbility = [
                                {
                                    action: 'manage',
                                    subject: 'all',
                                },
                            ]
                            // this.vm.$ability.update(userAbility)
                            localStorage.setItem('userAbility', JSON.stringify(userAbility))

                            window.location.reload();
                        }
                        else
                        {
                            router.push({ name: 'reset-password' });
                        }

                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Error',
                            confirmButtonColor: '#00b2a9',
                            text: res.error.response.data.message,
                        })

                    }
                });
            }

        }
    },
    // setup()
    // {
    //     const isPasswordVisible = ref(false)

    //     return {
    //         isPasswordVisible,
    //         // themeConfig
    //         appName: themeConfig.app.name,
    //         appLogo: themeConfig.app.logo,

    //         icons: {
    //             mdiChevronLeft,
    //         },
    //     }
    // },
}
</script>

<style lang="scss">
@import '@core/preset/preset/auth.scss';

::v-deep .v-text-field input {
    padding: 8px 8px 8px;
}

.code-img {
    height: 17px;
    margin: 3px 15px 0px 0px;
}

::v-deep .v-input__icon .v-icon {
    color: #00B2A9 !important;
}

.text {
    color: black;
}
</style>
